import React from 'react'
import LinkedinImg from '../Assets/Images/linkedin_white.avif'
import GithubImg from '../Assets/Images/github_white.avif'
import EmailImg from '../Assets/Images/email_white.avif'
import './Footer.scss'

const Footer = () => {
    return (
        <div className='Footer'>
            <div className='Social-media'>
                <ul>
                    <li><a href='https://www.linkedin.com/in/micha%C5%82-zar%C4%99ba-150a38139/'><img src={LinkedinImg} className='Social-icon' alt="linkedin icon" /><span className="Social-label">LinkedIn</span></a></li>
                    <li><a href='https://github.com/michalzareba0001'><img src={GithubImg} className='Social-icon' alt="Github icon" />Github</a></li>
                    <li><a href='mailto:michalzareba0001@gmail.com'><img src={EmailImg} className='Social-icon' alt="Email icon" />michalzareba0001@gmail.com</a></li>
                </ul>
            </div>
            <div className='Logo-container'>
                <h1>Portfolio</h1>
                <h2>by Michal Zareba</h2>
            </div>
        </div>
    )
}

export default Footer