import React, { useEffect } from 'react';
import myphoto from '../Assets/Images/my-photo.avif';
import './Matrix.scss';

const Matrix = () => {

    useEffect(() => {
        const canvas = document.getElementById('canvas');
        const ctx = canvas.getContext('2d');

        const parent = canvas.parentNode;
        canvas.width = parent.offsetWidth * 1.5;
        canvas.height = parent.offsetHeight * 1.5;

        const board = document.getElementById('board');

        function createSygnet() {
            const img = document.createElement('img');
            img.src = myphoto;
            img.id = 'sygnet';
            img.alt= 'Michał Zaręba';
            img.classList.add('sygnet');
            board.appendChild(img);
        }

        createSygnet();

        const codes = '#MZ@$&michalzareba©←↓→';

        const drops = [];
        for (let i = 0; i < canvas.width / 3; i++) {
            drops[i] = 1;
        }

        function draw() {
            ctx.fillStyle = 'rgba(0, 0, 0, 0.15)';
            ctx.fillRect(0, 0, canvas.width, canvas.height);

            
            ctx.fillStyle = '#FFD700';
            ctx.font = '25px monospace';
            for (let i = 0; i < drops.length; i++) {
                ctx.fillText(String.fromCharCode(codes.charCodeAt(Math.floor(Math.random() * codes.length))), i * 17, drops[i] * 17);
                if (drops[i] * 10 > canvas.height && Math.random() > 0.98) {
                    drops[i] = 1;
                }
                drops[i]++;
            }
        }

        
        const interval = setInterval(draw, 1000 / 15);

        setTimeout(() => {
            canvas.width = parent.offsetWidth * 1.5 ;
            canvas.height = parent.offsetHeight * 1.5;
        }, 5600);

        
        return () => clearInterval(interval);

    }, []); 

    return (
        <div className='matrix'>
            <canvas id='canvas' width='200' height='200'>
                Twoja przeglądarka nie obsługuje elementu canvas.
            </canvas>
            <div id='board'></div>
        </div>
    );
}

export default Matrix;
