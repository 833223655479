import React from 'react'
import './AboutMe.scss'
import Slider from "react-slick"
import Matrix from './Matrix'


const AboutMe = () => {
  var settings = {
    dots: false,
    autoplay: true,
    autoplaySpeed: 1200,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    vertical: true,
    arrows: false
  }

  return (
    <div className='AboutMe' id='AboutMe'>
      <div className='about-me-grid'>
        <div className='left'>
               <Matrix /> 
        </div>
        <div className='right'>
            <h1>Michał Zaręba</h1>
            <h2>Frontend developer</h2>
            <p>
            Years of self-development made me passionate about coding and creativity. I specialize in UI/UX design, SEO and performance optimization, and accessibility. Always seeking new opportunities for growth, I'm not afraid of challenges, and enjoy learning new things. I embrace modern technologies to deliver optimal solutions. </p>
            <h3>My Favorite Tools and Techniques:</h3>
            <Slider {...settings} className='tools-slider'>
              <div className='slider-content'>
                <h4 className='yellow'> HTML </h4>
              </div>
              <div className='slider-content'>
                <h4> JavaScript </h4>
              </div>
              <div className='slider-content'>
                <h4 className='yellow'> CSS </h4>
              </div>
              <div className='slider-content'>
                <h4> Sass </h4>
              </div>
              <div className='slider-content'>
                <h4 className='yellow'> React.js </h4>
              </div>
              <div className='slider-content'>
                <h4> React native </h4>
              </div>
              <div className='slider-content'>
                <h4 className='yellow'> Wordpress </h4>
              </div>
              <div className='slider-content'>
                <h4> Woocommerce </h4>
              </div>
              <div className='slider-content'>
                <h4 className='yellow'> Elementor </h4>
              </div>
              <div className='slider-content'>
                <h4> PHP </h4>
              </div>
              <div className='slider-content'>
                <h4 className='yellow'> Python </h4>
              </div>
              <div className='slider-content'>
                <h4> Linux </h4>
              </div>

            </Slider>
        </div>
        </div>

       </div>
  )
}

export default AboutMe