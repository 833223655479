import React, { useState } from 'react';
import './Contact.scss';
import LinkedinImg from '../Assets/Images/linkedin_white.avif';
import EmailImg from '../Assets/Images/email_white.avif';
import emailjs from 'emailjs-com';

const Contact = () => {
  const [form, setForm] = useState({
    name: '',
    company: '',
    email: '',
    phone: '',
    message: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({
      ...form,
      [name]: value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs.send('service_98c2a1h', 'template_zvzswom', form, '1Yj8JszEGCHskH6oe')
      .then((response) => {
        alert('Your message has been sent. I will contact you as soon as possible.')
        setForm({
          to_name: 'Michał',
          form_name: 'My Portfolio Page',
          name: '',
          company: '',
          email: '',
          phone: '',
          message: ''
        });
      }, (error) => {
        alert('Something went wrong. Sorry, the message could not be sent...')
      });
  };

  return (
    <div className='Contact' id='Contact'>
      <h2 className='title'>Contact</h2>
      <div className='contact-grid'>
        <div className='contact-left'>
          <h3>You can reach me via email and LinkedIn, <br /> or by using this contact form<span className='contact-arrow'> ➔ </span></h3>
          <div className='Social-media'>
            <ul>
              <li><a href='https://www.linkedin.com/in/micha%C5%82-zar%C4%99ba-150a38139/'><img src={LinkedinImg} className='Social-icon' alt="linkedin icon" /><span className="Social-label">LinkedIn</span></a></li>
              <li><a href='mailto:michalzareba0001@gmail.com'><img src={EmailImg} className='Social-icon' alt="Email icon" />michalzareba0001@gmail.com</a></li>
            </ul>
          </div>
        </div>
        <div className='contact-right'>
          <form onSubmit={handleSubmit} className='contact-form'>
            <input type='text' name='name' placeholder='Name (required)' aria-label='Name' value={form.name} onChange={handleChange} required />
            <input type='text' name='company' placeholder='Company' aria-label='Company' value={form.company} onChange={handleChange} />
            <input type='email' name='email' placeholder='Email (required)' aria-label='Email' value={form.email} onChange={handleChange} required />
            <input type='tel' name='phone' placeholder='Phone' aria-label='Phone' value={form.phone} onChange={handleChange} />
            <textarea name='message' placeholder='Your message...' aria-label='Your message' value={form.message} onChange={handleChange} />
            <button type='submit'>Send</button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Contact;
