import React from 'react'
import './MySkills.scss'
import aispec from '../Assets/Images/aispec.avif'
import design from '../Assets/Images/design.avif'
import ecommerce from '../Assets/Images/ecommerce.avif'
import mobileapp from '../Assets/Images/mobileapp.avif'
import specialfx from '../Assets/Images/specialfx.avif'
import webdevelopment from '../Assets/Images/webdevelopment.avif'
import webanalysis from '../Assets/Images/websideanalis.avif'
import webopt from '../Assets/Images/websideoptimatization.avif'

const MySkills = () => {
  return (
    <div className='MySkills' id='MySkills'>
      <h2 className='title'>My Skills</h2>
      <div className='my-skills-content'>

        <div className='skill skill-one'>
          <div className='skill-icon'>
            <img src={webdevelopment} alt='' width='64' height='64'/>
            <h3>Web Development</h3>
          </div>
          <div className='skill-content'>
            <p>Creating websites using a variety of technologies, including WordPress, React.js, HTML, JavaScript, and CSS.</p>
          </div>
        </div>

        <div className='skill skill-two'>
          <div className='skill-icon'>
            <img src={ecommerce} alt='' width='64' height='64'/>
            <h3>E-commerce Development</h3>
          </div>
          <div className='skill-content'>
            <p>Building robust online stores using WordPress and WooCommerce, offering seamless shopping experiences and efficient management.</p>
          </div>
        </div>

        <div className='skill skill-three'>
          <div className='skill-icon'>
            <img src={mobileapp} alt='' width='64' height='64'/>
            <h3>Mobile Applications</h3>
          </div>
          <div className='skill-content'>
            <p>Developing mobile applications using React Native and Expo, ensuring cross-platform compatibility and a smooth user experience.
            </p>
          </div>
        </div>

        <div className='skill skill-four'>
          <div className='skill-icon'>
            <img src={webopt} alt='' width='64' height='64'/>
            <h3>Website Optimization</h3>
          </div>
          <div className='skill-content'>
            <p>Optimizing  websites for performance, SEO, and accessibility, ensuring fast loading times, higher search engine rankings, and inclusive user experiences.</p>
          </div>
        </div>

        <div className='skill skill-five'>
          <div className='skill-icon'>
            <img src={specialfx} alt='' width='64' height='64'/>
            <h3>Animations and Special Effects</h3>
          </div>
          <div className='skill-content'>
            <p>Creating engaging animations and special effects for websites, enhancing user interaction and providing a dynamic, visually appealing experience.</p>
          </div>

        </div>

        <div className='skill skill-six'>
          <div className='skill-icon'>
            <img src={webanalysis} alt='' width='64' height='64'/>
            <h3>Website Analysis</h3>
          </div>
          <div className='skill-content'>
            <p>Conducting thorough website analysis to identify and fix errors, as well as to add new functionalities, enhancing overall functionality and user experience.</p>
          </div>

        </div>

        <div className='skill skill-seven'>
          <div className='skill-icon'>
          <img src={design} alt='' width='64' height='64'/>
            <h3>Graphic Design Basics</h3>
          </div>
          <div className='skill-content'>
            <p>Foundational skills in computer graphics using Figma, Adobe XD, Photoshop, and GIMP.</p>
          </div>

        </div>
        <div className='skill skill-eight'>
          <div className='skill-icon'>
            <img src={aispec} alt='' width='64' height='64' />
            <h3>AI Specialist</h3>
          </div>
          <div className='skill-content'>
            <p>
            Advanced user of artificial intelligence, proficient in Python, Gemini API, OpenAI API, and image generation technologies.</p>
          </div>

        </div>
      </div>
    </div>
  )
}

export default MySkills