import React from 'react';
import './Slide.scss';

const Slide = ({ content }) => (
    <div
        className='Slide'
        style={{ backgroundImage: `url(${content.image})` }}
        onClick={() => window.open(content.link, '_blank')}
    >
        <div className='SlideContent'>
            <h3>{content.title}</h3>
            <h4>{content.tools}</h4>
            <p>{content.description}</p>
        </div>
    </div>
);

export default Slide;
