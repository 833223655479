import React from 'react';
import './App.scss';
import Header from './Components/Header'
import AboutMe from './Components/AboutMe';
import MySkills from './Components/MySkills';
import Portfolio from './Components/Portfolio';
import Contact from './Components/Contact';
import Footer from './Components/Footer';

function App() {
  return (
    <div className="App">
      <Header />
      <AboutMe />
      <Portfolio />
      <MySkills />
      <Contact />
      <Footer />
    </div>
  );
}

export default App;
